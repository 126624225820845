$small: 414px;

img {
  display: block; }

h3.link_owner {
  margin-top: 1em;
  margin-bottom: 0; }

.img_alt {
  text-align: center; }

.h230 {
  height: 230px;
  @media screen and (max-width: $small) {
    height: auto;
    margin-bottom: 2em; } }

.w30 {
  width: 30%;
  @media screen and (max-width: $small) {
    width: auto; } }

.float_left, .float_right {
  @media screen and (max-width: $small) {
    width: 100%;
    float: none;
    clear: both; } }
